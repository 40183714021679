<template>
  <div class="progress" :class="{'show' : isProgress}">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Progress',
  created () {
    console.log(this.$store.state.isProgress)
  },
  computed: {
    isProgress () {
      return this.$store.state.isProgress
    }
  }
}
</script>
