<template>
  <div class="dashboard">
    <!---Bills-->
    <div class="card mb-16 card--list">
      <div class="card__title">
        <h4>Rentas pendientes de pago</h4>
        <div @click="showBills= !showBills" class="card__arrow" :class="{'show' : showBills}">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Chevron Up</title>
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
                  d="M112 328l144-144 144 144"/>
          </svg>
        </div>
      </div>
      <div class="card__wrap" :class="{'hide' : showBills}">
        <div class="card__content">
          <!--Table-->
          <div class="table">
            <div class="table__header table__header--five">
              <div><span>PROPIEDAD</span></div>
              <div><span>INQUILINO</span></div>
              <div><span>DOCUMENTO</span></div>
              <div><span>FECHA DE PAGO</span></div>
              <div><span>RENTA</span></div>
            </div>
            <div class="table__content">
              <template v-if="bills.length>0">
                <div v-for="(c, index) in bills" class="row row--ns  row--five" :key="index">
                  <div class="row__item">
                    <span class="name">PROPIEDAD</span>
                    <span>{{ c.client.local.description }}</span>
                  </div>
                  <div class="row__item">
                    <span class="name">INQUILINO</span>
                    <span>{{ c.client.name }}</span>
                  </div>
                  <div class="row__item">
                    <span class="name">DOCUMENTO</span>
                    <span>{{ c.client.document }}</span>
                  </div>
                  <div class="row__item">
                    <span class="name">FECHA DE PAGO</span>
                    <span>{{ moment(c.deadline).lang('es').format('DD[/]MMMM[/]YYYY') }}</span>
                  </div>
                  <div class="row__item badge badge--error">
                  <span>{{
                      Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: `${c.client.currency.code}`
                      }).format(c.total)
                    }}</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="empty">
                  <span>Sin datos para mostrar</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---Clients taxes-->
    <div class="card mb-16 card--list">
      <div class="card__title">
        <h4>Impuestos pendientes de pago</h4>
        <div @click="showClientsTaxes= !showClientsTaxes" class="card__arrow" :class="{'show' : showClientsTaxes}">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Chevron Up</title>
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
                  d="M112 328l144-144 144 144"/>
          </svg>
        </div>
      </div>
      <div class="card__wrap" :class="{'hide' : showClientsTaxes}">
        <div class="card__content">
          <!--Table-->
          <div class="table">
            <div class="table__header table__header--five">
              <div><span>PROPIEDAD</span></div>
              <div><span>INQUILINO</span></div>
              <div><span>DOCUMENTO</span></div>
              <div><span>FECHA DE PAGO</span></div>
              <div><span>TOTAL</span></div>
            </div>
            <div class="table__content">
              <template v-if="clientsTaxes.length>0">
                <div v-for="(c, index) in clientsTaxes" class="row row--ns  row--five" :key="index">
                  <div class="row__item">
                    <span class="name">PROPIEDAD</span>
                    <span>{{ c.client.local }}</span>
                  </div>
                  <div class="row__item">
                    <span class="name">INQUILINO</span>
                    <span>{{ c.client.name }}</span>
                  </div>
                  <div class="row__item">
                    <span class="name">DOCUMENTO</span>
                    <span>{{ c.client.document }}</span>
                  </div>
                  <div class="row__item">
                    <span class="name">FECHA DE PAGO</span>
                    <span>{{ moment(c.deadline).lang('es').format('DD[/]MM[/]YYYY') }}</span>
                  </div>
                  <div class="row__item badge badge--error">
                  <span>{{
                      Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: `${c.client.currency.code}`
                      }).format(c.total)
                    }}</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="empty">
                  <span>Sin datos para mostrar</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---Property taxes-->
    <div class="card mb-16 card--list">
      <div class="card__title">
        <h4>Impuestos prediales pendientes de pago</h4>
        <div @click="showPropertyTaxes=  !showPropertyTaxes" class="card__arrow" :class="{'show' : showPropertyTaxes}">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Chevron Up</title>
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
                  d="M112 328l144-144 144 144"/>
          </svg>
        </div>
      </div>
      <div class="card__wrap" :class="{'hide' : showPropertyTaxes}">
        <div class="card__content">
          <!--Table-->
          <div class="table">
            <div class="table__header table__header--three">
              <div><span>PROPIEDAD</span></div>
              <div><span>FECHA DE PAGO</span></div>
              <div><span>TOTAL</span></div>
            </div>
            <div class="table__content">
              <template v-if="propertyTaxes.length>0">
                <div v-for="(c, index) in propertyTaxes" class="row row--ns  row--three"
                     :key="index">
                  <div class="row__item">
                    <span class="name">PROPIEDAD</span>
                    <span>{{ c.estate.description }}</span>
                  </div>
                  <div class="row__item">
                    <span class="name">FECHA DE PAGO</span>
                    <span>{{ moment(c.deadline).lang('es').format('DD[/]MM[/]YYYY') }}</span>
                  </div>
                  <div class="row__item badge badge--error">
                  <span>{{
                      Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: `${c.currency.code}`
                      }).format(c.total)
                    }}</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="empty">
                  <span>Sin datos para mostrar</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---Municipal taxes-->
    <div class="card mb-16 card--list">
      <div class="card__title">
        <h4>Arbitrios pendientes de pago</h4>
        <div @click="showMunicipalTaxes= !showMunicipalTaxes" class="card__arrow"
             :class="{'show' : showMunicipalTaxes}">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Chevron Up</title>
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
                  d="M112 328l144-144 144 144"/>
          </svg>
        </div>
      </div>
      <div class="card__wrap" :class="{'hide' : showMunicipalTaxes}">
        <div class="card__content">
          <!--Table-->
          <div class="table">
            <div class="table__header table__header--three">
              <div><span>PROPIEDAD</span></div>
              <div><span>FECHA DE PAGO</span></div>
              <div><span>TOTAL</span></div>
            </div>
            <div class="table__content">
              <template v-if="municipalTaxes.length>0">
                <div v-for="(c, index) in municipalTaxes" class="row row--ns  row--three" :key="index">
                  <div class="row__item">
                    <span class="name">PROPIEDAD</span>
                    <span>{{ c.estate.description }}</span>
                  </div>
                  <div class="row__item">
                    <span class="name">FECHA DE PAGO</span>
                    <span>{{ moment(c.deadline).lang('es').format('DD[/]MM[/]YYYY') }}</span>
                  </div>
                  <div class="row__item badge badge--error">
                  <span>{{
                      Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: `${c.currency.code}`
                      }).format(c.total)
                    }}</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="empty">
                  <span>Sin datos para mostrar</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Timestamp, db } from '@/firebase/firebaseConfig'
import moment from 'moment'

export default {
  name: 'Home',
  data () {
    return {
      bills: [],
      clientsTaxes: [],
      propertyTaxes: [],
      municipalTaxes: [],
      moment: moment,
      showBills: false,
      showClientsTaxes: false,
      showPropertyTaxes: false,
      showMunicipalTaxes: false
    }
  },
  components: {},
  async created () {
    try {
      this.$store.dispatch('showProgress')
      await this.getBills()
      await this.getClientsTaxes()
      await this.getPropertyTaxes()
    } catch (e) {
      console.log(e)
      this.$toast.error('Error al cargar datos')
    } finally {
      this.$store.dispatch('hideProgress')
    }
  },
  methods: {
    /**
     * Get bills
     * @returns {Promise<void>}
     */
    async getBills () {
      const year = new Date().getFullYear()
      const month = new Date().getMonth()
      const day = new Date().getDate()
      const startTime = Timestamp.fromDate(new Date(year + '/' + (month + 1) + '/' + day))
      const querySnap = await db.collectionGroup('bills')
        .orderBy('deadline', 'asc')
        .where('deadline', '<=', startTime)
        .where('paid', '==', false).get()
      querySnap.forEach((doc) => {
        const obj = {
          id: doc.id,
          ...doc.data()
        }
        obj.deadline = doc.data().deadline.toDate()
        this.bills.push(obj)
      })
    },
    /**
     * Clients taxes
     * @returns {Promise<void>}
     */
    async getClientsTaxes () {
      const year = new Date().getFullYear()
      const month = new Date().getMonth()
      const day = new Date().getDate()
      const startTime = Timestamp.fromDate(new Date(year + '/' + (month + 1) + '/' + day))
      const querySnap = await db.collectionGroup('taxes')
        .orderBy('deadline', 'asc')
        .where('deadline', '<=', startTime)
        .where('paid', '==', false).get()
      querySnap.forEach((doc) => {
        const obj = {
          id: doc.id,
          ...doc.data()
        }
        obj.deadline = doc.data().deadline.toDate()
        this.clientsTaxes.push(obj)
      })
    },
    /**
     * Property taxes
     */
    async getPropertyTaxes () {
      const year = new Date().getFullYear()
      const month = new Date().getMonth()
      const day = new Date().getDate()
      const startTime = Timestamp.fromDate(new Date(year + '/' + (month + 1) + '/' + day))
      const querySnap = await db.collectionGroup('propertyTaxes')
        .orderBy('deadline', 'asc')
        .where('deadline', '<=', startTime)
        .where('paid', '==', false).get()
      querySnap.forEach((doc) => {
        const obj = {
          id: doc.id,
          ...doc.data()
        }
        obj.deadline = doc.data().deadline.toDate()
        if (obj.type === 'Predial') {
          this.propertyTaxes.push(obj)
        } else {
          this.municipalTaxes.push(obj)
        }
      })
    }
  }
}
</script>
