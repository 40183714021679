import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { auth } from '@/firebase/firebaseConfig'
import store from '@/store'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login')
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      auth: true
    }
  },
  {
    path: '/stats',
    name: 'stats',
    component: () => import('../views/Stats'),
    meta: {
      auth: true
    }
  },
  {
    path: '/stats2',
    name: 'stats2',
    component: () => import('../views/Stats2'),
    meta: {
      auth: true
    }
  },
  {
    path: '/tenants',
    name: 'tenants',
    component: () => import(/* webpackChunkName: "about" */ '../views/tenants/App'),
    meta: {
      auth: true
    }
  },
  {
    path: '/tenants/bills/:id',
    name: 'bills',
    component: () => import(/* webpackChunkName: "about" */ '../views/bills/App'),
    meta: {
      auth: true
    }
  },
  {
    path: '/tenants/taxes/:id',
    name: 'taxes',
    component: () => import(/* webpackChunkName: "about" */ '../views/taxes/App'),
    meta: {
      auth: true
    }
  },
  {
    path: '/tenants/contracts/:id',
    name: 'contracts',
    component: () => import(/* webpackChunkName: "about" */ '../views/contracts/App'),
    meta: {
      auth: true
    }
  },
  {
    path: '/estates',
    name: 'estates',
    component: () => import(/* webpackChunkName: "about" */ '../views/estates/App'),
    meta: {
      auth: true
    }
  },
  {
    path: '/estates/taxes/:id',
    name: 'estates-taxes',
    component: () => import(/* webpackChunkName: "about" */ '../views/estates/taxes/App'),
    meta: {
      auth: true
    }
  },
  {
    path: '/expenses',
    name: 'expenses',
    component: () => import(/* webpackChunkName: "about" */ '../views/expenses/App'),
    meta: {
      auth: true
    }
  },
  {
    path: '/sales',
    name: 'sales',
    component: () => import(/* webpackChunkName: "about" */ '../views/sales/App'),
    meta: {
      auth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  auth.onAuthStateChanged(() => {
    // get firebase current user
    const firebaseCurrentUser = auth.currentUser
    // If auth required, check login. If login fails redirect to login page
    if (to.meta.auth) {
      if (!(firebaseCurrentUser)) {
        store.dispatch('showLogin')
        return next('/login')
      } else {
        store.dispatch('hideLogin')
        return next()
      }
    } else {
      return next()
    }
  })
})

export default router
