import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'

// Initialize Firebase
let config = {
  apiKey: 'AIzaSyCGhc3cmBRpLY_zM28_5nvhDuOLtEotKf8',
  authDomain: 'inqi-5fe1e.firebaseapp.com',
  projectId: 'inqi-5fe1e',
  storageBucket: 'inqi-5fe1e.appspot.com',
  messagingSenderId: '429312416030',
  appId: '1:429312416030:web:f32094d5a4a1bd2eaf110f',
  measurementId: 'G-3QD102EWNB'
}

// Marlon
if (window.location.host === 'marlon.inqiapp.com') {
  config = {
    apiKey: 'AIzaSyDMq59SlB30VTVNFXKBYDFtaIRZBuXMJSU',
    authDomain: 'marlon-1dd4f.firebaseapp.com',
    projectId: 'marlon-1dd4f',
    storageBucket: 'marlon-1dd4f.appspot.com',
    messagingSenderId: '515679789594',
    appId: '1:515679789594:web:6432c8516565b5c250e7a3'
  }
}

firebase.initializeApp(config)
firebase.analytics()

// utils
const db = firebase.firestore()
const storage = firebase.storage()
const FieldValue = firebase.firestore.FieldValue
const Timestamp = firebase.firestore.Timestamp
const auth = firebase.auth()

export {
  db,
  auth,
  FieldValue,
  Timestamp,
  storage
}
