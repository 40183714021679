<template>
  <Menu v-if="!login"/>
  <Progress/>
  <div class="content" :class="{'content--login':login}">
    <router-view v-slot="{ Component }">
      <transition name="route" mode="out-in" appear>
        <component :is="Component">
          <template #header>
            <app-header></app-header>
          </template>
        </component>
      </transition>
    </router-view>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import Progress from '@/components/Progress'

export default {
  name: 'App',
  components: {
    Menu,
    Progress
  },
  computed: {
    login () {
      return this.$store.state.login
    }
  }
}
</script>

<style lang="scss">
@import "assets/scss/global";

.route-enter-from {
  opacity: 0;
}

.route-enter-active {
  transition: all 0.2s ease-out;
}

.route-leave-to {
  opacity: 0;
}

.route-leave-active {
  transition: all 0.2s ease-in;
}

</style>
