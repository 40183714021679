<template>
  <div class="menu" :class="{'show': showMenuMobile }">
    <!--Menu-->
    <ul>
      <li>
        <router-link to="/" :class="{'active': ($route.name ==='home')}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-home">
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          Dashboard
        </router-link>
      </li>
      <li>
        <router-link to="/tenants"
                     :class="{'active': ($route.name ==='tenants' || $route.name ==='bills'|| $route.name ==='taxes' || $route.name ==='contracts')}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-users">
            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          </svg>
          Inquilinos
        </router-link>
      </li>
      <li>
        <router-link to="/estates" :class="{'active':  ($route.name ==='estates' || $route.name ==='estates-taxes')}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-briefcase">
            <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
          </svg>
          Propiedades
        </router-link>
      </li>
      <li>
        <router-link to="/expenses" :class="{'active':  ($route.name ==='expenses')}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-minus-circle">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="8" y1="12" x2="16" y2="12"></line>
          </svg>
          Gastos
        </router-link>
      </li>
      <li>
        <router-link to="/sales" :class="{'active':  ($route.name ==='sales')}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path
            d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
          Ventas
        </router-link>
      </li>
      <li>
        <router-link to="/stats" :class="{'active': ($route.name ==='stats')}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-pie-chart">
            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
            <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
          </svg>
          Estadísticas
        </router-link>
      </li>
      <li>
        <router-link to="/stats2" :class="{'active': ($route.name ==='stats2')}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-pie-chart">
            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
            <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
          </svg>
          Estadísticas Prop.
        </router-link>
      </li>
    </ul>
    <!--End-->
    <!--Close-->
    <div @click="showMenuMobile =false" class="menu__close">
      <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Close</title>
        <path
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="32"
          d="M368 368L144 144M368 144L144 368"
        />
      </svg>
    </div>
    <!--Log out-->
    <div class="menu__logout desktop">
      <span @click="logout">Cerrar sesión</span>
    </div>
    <div @click="logout" class="menu__logout mobile">
      <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Log Out</title>
        <path
          d="M304 336v40a40 40 0 01-40 40H104a40 40 0 01-40-40V136a40 40 0 0140-40h152c22.09 0 48 17.91 48 40v40M368 336l80-80-80-80M176 256h256"
          fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
      </svg>
    </div>
    <!--End-->
    <!--Logo-->
    <svg class="logo" xmlns="http://www.w3.org/2000/svg" width="436.722" height="196.02" viewBox="0 0 436.722 196.02">
      <g id="Grupo_3" data-name="Grupo 3" transform="translate(-181.639 -301.99)">
        <g id="Grupo_1" data-name="Grupo 1">
          <path id="Trazado_1" data-name="Trazado 1"
                d="M200.442,323.826a18.578,18.578,0,1,1,18.579,18.58,18.578,18.578,0,0,1-18.579-18.58" fill="#b51a3e"/>
          <path id="Trazado_2" data-name="Trazado 2"
                d="M242.222,363.76c-.627-4.154-3.625-14.423-17.971-14.423h-1.5c-2.2.039-4.938.278-5,.284l-30.847,2.936a4.942,4.942,0,0,0-1.969.822c-.08.065-.133.139-.207.207a4.5,4.5,0,0,0-1.3,2.031,9.119,9.119,0,0,0-.417,1.358c0,.017-.012.023-.012.044,0,.006,0,.006,0,.015s-.017.088-.017.088l-1.34,16.475-.009.568a3.312,3.312,0,0,0,.991,2.365,3.357,3.357,0,0,0,2.365,1.017l17.731-1.836a4.185,4.185,0,0,1,4.586,3.743l6.091,61c.751,4.6,4.21,14.987,20.283,15.162,14.023.153,16.365-8.152,16.427-13.5Z"
                fill="#b51a3e"/>
        </g>
        <path id="Trazado_3" data-name="Trazado 3"
              d="M336.106,377.23q-4.62-3.738-12.321-3.74a33.57,33.57,0,0,0-9.679,1.32,44.848,44.848,0,0,0-8.14,3.3v77.88a43.541,43.541,0,0,1-6.16,1.21,64.762,64.762,0,0,1-8.8.55q-9.9,0-14.3-3.19t-4.4-12.431V374.15a16,16,0,0,1,2.42-9.24,26.743,26.743,0,0,1,6.82-6.6q7.038-5.058,18.15-8.14a91.712,91.712,0,0,1,24.531-3.08q23.976,0,37.068,10.56t13.092,30.14v68.2a53.11,53.11,0,0,1-6.381,1.21,64.767,64.767,0,0,1-8.8.55q-9.9,0-14.189-3.19t-4.289-12.431v-53.46Q340.727,380.972,336.106,377.23Z"
              fill="#b51a3e"/>
        <path id="Trazado_4" data-name="Trazado 4"
              d="M501.545,496.03a52.347,52.347,0,0,1-6.271,1.32,54.315,54.315,0,0,1-8.689.66q-9.9,0-14.3-3.3t-4.4-12.54V456.43a55.533,55.533,0,0,1-8.691,2.2,59.83,59.83,0,0,1-10.449.88,64.675,64.675,0,0,1-21.78-3.519,45.966,45.966,0,0,1-28.49-27.941,67.575,67.575,0,0,1-4.069-24.42q0-14.074,4.289-24.639a47.186,47.186,0,0,1,12.1-17.6,52.163,52.163,0,0,1,18.7-10.67,76.575,76.575,0,0,1,24.309-3.63,82.2,82.2,0,0,1,22.55,2.97q10.447,2.97,16.17,6.71a21.929,21.929,0,0,1,6.71,6.49,17.154,17.154,0,0,1,2.311,9.35Zm-47.96-63.14a28.056,28.056,0,0,0,14.519-3.74V376.79a39.927,39.927,0,0,0-5.719-2.2,26.561,26.561,0,0,0-7.92-1.1q-11.88,0-18.92,7.37t-7.04,22.769q0,15.182,6.6,22.221T453.585,432.89Z"
              fill="#b51a3e"/>
        <path id="Trazado_5" data-name="Trazado 5"
              d="M525.744,320.03a17.57,17.57,0,0,1,5.17-12.76q5.166-5.28,13.529-5.28t13.53,5.28a17.582,17.582,0,0,1,5.17,12.76,17.841,17.841,0,0,1-5.17,12.98q-5.171,5.28-13.53,5.28t-13.529-5.28A17.829,17.829,0,0,1,525.744,320.03Zm35.42,135.96A43.571,43.571,0,0,1,555,457.2a64.774,64.774,0,0,1-8.8.55q-9.9,0-14.191-3.19t-4.29-12.431V351.71a52.8,52.8,0,0,1,6.161-1.32,53.732,53.732,0,0,1,8.8-.66q9.9,0,14.191,3.3t4.29,12.54Z"
              fill="#b51a3e"/>
        <path id="Trazado_6" data-name="Trazado 6"
              d="M579.2,439.93a20.23,20.23,0,0,1,5.17-14.08q5.166-5.718,14.41-5.721,9.018,0,14.3,5.721a21.412,21.412,0,0,1,0,28.159q-5.28,5.719-14.3,5.721-9.24,0-14.41-5.721A20.214,20.214,0,0,1,579.2,439.93Z"
              fill="#b51a3e"/>
      </g>
    </svg>
  </div>
  <!--Hamburger-->
  <div class="hamburger" @click="showMenuMobile= !showMenuMobile">
    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Menu</title>
      <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="32"
        d="M80 160h352M80 256h352M80 352h352"
      />
    </svg>
  </div>
  <!--End-->
</template>

<script>
import { auth } from '@/firebase/firebaseConfig'

export default {
  name: 'Menu',
  data () {
    return {
      showMenuMobile: false
    }
  },
  watch: {
    '$route' (value) {
      this.showMenuMobile = false
    }
  },
  methods: {
    async logout () {
      try {
        await auth.signOut()
        this.$store.dispatch('showLogin')
        await this.$router.push('/login')
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
